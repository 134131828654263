/* eslint-disable react/require-default-props */
import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from 'react-use-gesture';
import { CarouselBars } from '@vds/carousels';

import styles from './Carousel.module.scss';
import useMeasure from '../../lib/useMeasure';
import { useCurrentBreakpoint } from '../../lib/useBreakpoint';
import BodyText from '../BodyText';

const MOBILE_SLIDE_WIDTH_MULTIPLIER = 0.8125;

type CarouselProps = {
  children: JSX.Element | JSX.Element[];
  removeCarouselFrame?: boolean;
};

const clamp = (number: number, min: number, max: number): number =>
  Math.max(min, Math.min(number, max));

const Carousel = ({ children, removeCarouselFrame }: CarouselProps): JSX.Element => {
  const carouselElRef = useRef<HTMLDivElement | null>(null);

  const { width: carouselWidth } = useMeasure(carouselElRef);
  const currentBreakpoint = useCurrentBreakpoint();
  const isMobile = currentBreakpoint === 'small';

  const [currentSlide, setCurrentSlide] = useState(0);

  const slideWidth = isMobile
    ? Math.round(carouselWidth * MOBILE_SLIDE_WIDTH_MULTIPLIER)
    : carouselWidth;
  const slideCount = React.Children.count(children);

  const offset = useRef(0);
  const [spring, setSpring] = useSpring(() => ({
    x: `${isMobile ? 10 : 0}px`,
    width: `${slideWidth * slideCount}px`,
  }));

  const updateViewport = useCallback(() => {
    setSpring({
      x: `${offset.current + currentSlide * -slideWidth + (isMobile ? 10 : 0)}px`,
      immediate: offset.current !== 0,
      width: `${slideWidth * slideCount}px`,
    });
  }, [setSpring, currentSlide, slideWidth, isMobile, slideCount]);

  const moveToSlide = useCallback(
    (slideIndex) => {
      setCurrentSlide((oldCurrent) => {
        if (oldCurrent !== slideIndex) {
          return slideIndex;
        }
        updateViewport();
        return oldCurrent;
      });
    },
    [updateViewport]
  );

  useLayoutEffect(() => {
    updateViewport();
  }, [currentSlide, updateViewport, slideWidth]);

  const bind = useDrag(
    ({ down, movement: [movementX], swipe: [swipeX] }) => {
      if (swipeX !== 0) {
        offset.current = 0;

        const newSlide = clamp(currentSlide + swipeX * -1, 0, slideCount - 1);
        moveToSlide(newSlide);
      } else if (down) {
        offset.current = movementX;
        updateViewport();
      } else {
        offset.current = 0;

        if (Math.abs(movementX) > slideWidth / 2) {
          const newSlide = clamp(currentSlide + Math.sign(movementX) * -1, 0, slideCount - 1);
          moveToSlide(newSlide);
        } else {
          updateViewport();
        }
      }
    },
    { axis: 'x', eventOptions: { capture: true } }
  );

  const goToPrevSlide = (): void => {
    setCurrentSlide(() => (currentSlide !== 0 ? currentSlide - 1 : currentSlide));
  };

  const goToNextSlide = (): void => {
    setCurrentSlide(() => (currentSlide !== slideCount - 1 ? currentSlide + 1 : currentSlide));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyEvent = (e: any): void => {
    if (e.key === 'ArrowLeft') {
      goToPrevSlide();
    }

    if (e.key === 'ArrowRight') {
      goToNextSlide();
    }
  };

  return (
    <div
      className={styles.Carousel}
      ref={carouselElRef}
      role="region"
      aria-roledescription="carousel"
    >
      <div role="presentation" onKeyUp={handleKeyEvent}>
        <div className={`${styles.CarouselViewport} ${removeCarouselFrame ? styles.unframed : ''}`}>
          <animated.ul
            aria-live="polite"
            className={styles.CarouselTrack}
            // The spread operator is taken directly from the sandbox examples.
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bind()}
            style={{
              width: `${slideWidth * slideCount}px`,
              transform: spring.x.to((x) => `translateX(${x})`),
            }}
          >
            {React.Children.map(children, (Child, index) => (
              <li
                style={{ width: `${slideWidth}px` }}
                aria-hidden={index !== currentSlide}
                aria-roledescription="slide"
                aria-label={`${index + 1} of ${slideCount}`}
                role="group"
                tabIndex={index === currentSlide ? 0 : -1}
              >
                {Child}
              </li>
            ))}
          </animated.ul>
        </div>
        {isMobile ? (
          <div className={styles.CarouselBars}>
            <CarouselBars
              selectedSlide={currentSlide}
              slideCount={slideCount}
              onChange={(index: number) => {
                setCurrentSlide(index);
              }}
              uniqueId="tbd"
            />
          </div>
        ) : (
          <div className={styles.CarouselNav}>
            <div className={styles.CarouselNavProgress}>
              <BodyText textSize="small">
                <>
                  {currentSlide + 1} / {slideCount}
                </>
              </BodyText>
            </div>
            <div className={styles.CarouselNavButtons}>
              <button
                type="button"
                onClick={() => goToPrevSlide()}
                aria-label="Go to previous slide"
                aria-disabled={currentSlide === 0}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.1 18l.8-.8-7.7-7.7H18V8.4H2.2L9.9.8 9.1 0 0 9z"
                    fill="#000"
                    fillRule="nonzero"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => goToNextSlide()}
                aria-label="Go to next slide"
                aria-disabled={currentSlide === slideCount - 1}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.679.165L7.884.96l7.476 7.478H0v1.124h15.36L7.884 17.04l.795.795L17.515 9z"
                    fill="#000"
                    fillRule="nonzero"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
