/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';

import styles from './Autocomplete.module.scss';

import AutocompleteResults from '../AutocompleteResults';

import JSONObject from '../../models/JSONObject';

import { AppContext } from '../../containers/App';

const options: Fuse.IFuseOptions<JSONObject> = {
  keys: [
    'section.text.data.name',
    'section.subsection.data.data.name',
    'section.subsection.data.text.data.name',
    'section.subsection.data.text.data.data.name',
    'section.subsection.data.data.data.name',
    'section.subsection.data.data.text.data.name',
    'section.subsection.data.data.text.data.data.name',
    'section.subsection.data.data.data.data.name',
    'section.subsection.data.data.data.text.data.name',
    'section.subsection.data.data.data.text.data.data.name',
    'section.subsection.data.data.data.data.data.name',
    'section.subsection.data.data.data.data.text.data.name',
    'section.subsection.data.data.data.data.text.data.data.name',
    'section.subsection.data.data.caption.data.name',
  ],
  includeScore: true,
  includeMatches: true,
  distance: 500,
  threshold: 0.01,
  ignoreLocation: true,
};

const fuseSearch = (
  dataToSearch: JSONObject[],
  searchOptions: Fuse.IFuseOptions<JSONObject> | undefined,
  termToSearch: string | Fuse.Expression
): Fuse.FuseResult<JSONObject>[] => {
  const fuse = new Fuse(dataToSearch, searchOptions);
  const results = fuse.search(termToSearch);
  return results;
};

const formatOptions = (results: string[], searchTerm: string): string[] => {
  const autocompleteOptions: string[] = [];
  if (results.length !== 0) {
    // TODO: split out into separate functions
    for (let i = 0; i < results.length; i += 1) {
      // remove html
      const html = results[i];
      const div = document.createElement('div');
      div.innerHTML = html;
      const text = div.textContent || div.innerText || '';
      let beginning: number | undefined = -1;
      if (text.search(searchTerm) !== -1) {
        beginning = text.search(searchTerm);
      } else {
        const str2 = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);
        beginning = text.search(str2);
      }
      let end = beginning! + searchTerm.length;

      for (let j = end; j < text.length; j += 1) {
        if (
          text.charAt(end) !== ' ' &&
          text.charAt(end) !== ',' &&
          text.charAt(end) !== '' &&
          text.charAt(end) !== '.' &&
          text.charAt(end) !== '<' &&
          text.charAt(end) !== '@' &&
          text.charAt(end) !== ':' &&
          text.charAt(end) !== '/' &&
          text.charAt(end) !== '_'
        ) {
          end += 1;
        }
      }
      for (let j = beginning!; j > 0; j -= 1) {
        if (
          text.charAt(beginning! - 1) !== ' ' &&
          text.charAt(beginning! - 1) !== ',' &&
          text.charAt(beginning! - 1) !== '' &&
          text.charAt(beginning! - 1) !== '.' &&
          text.charAt(beginning! - 1) !== '>' &&
          text.charAt(beginning! - 1) !== '@' &&
          text.charAt(beginning! - 1) !== '(' &&
          text.charAt(beginning! - 1) !== '/' &&
          text.charAt(beginning! - 1) !== '_'
        ) {
          beginning! -= 1;
        }
      }
      if (
        autocompleteOptions!.includes(text.slice(beginning, end).toLowerCase()) === false &&
        text.slice(beginning, end).toLowerCase() !== ''
      ) {
        autocompleteOptions.push(text.slice(beginning, end).toLowerCase());
      }
    }
  }
  return autocompleteOptions;
};

type SearchProps = {
  searchTerm: string;
};

const Autocomplete = ({ searchTerm }: SearchProps): JSX.Element => {
  const { appDocument } = useContext(AppContext);

  const [searchData, setSearchData] = useState<JSONObject[] | []>([]);

  useEffect(() => {
    if (appDocument && JSON.stringify(appDocument) !== '{}') {
      setSearchData(appDocument.data.map((page: JSONObject) => page));
    }
  }, [appDocument]);

  const formatResults = useCallback((results: Fuse.FuseResult<JSONObject>[]): string[] | [] => {
    const allResults: string[] = [];
    results.forEach((item) => {
      if (item.item.displayName !== 'Home Page') {
        item.matches?.forEach((result) => {
          allResults.push(result!.value!);
        });
      }
    });
    return allResults;
  }, []);

  const results = formatResults(fuseSearch(searchData, options, searchTerm));

  return (
    // {results && }
    <div className={styles.Autocomplete}>
      <AutocompleteResults
        results={formatOptions(results.slice(0, 6), searchTerm)}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default Autocomplete;
