import { useState, useEffect, useCallback, useReducer, useContext } from 'react';
import Fuse from 'fuse.js';

import styles from './Search.module.scss';

import Pagination from '../Pagination';
import SearchResultList from '../SearchResultList';

import JSONObject from '../../models/JSONObject';
import SearchResultListProps from '../../models/SearchResultListProps';

import { AppContext } from '../../containers/App';

import { useCurrentBreakpoint } from '../../lib/useBreakpoint';

const options: Fuse.IFuseOptions<JSONObject> = {
  keys: [
    'section.title.name',
    'section.subtitle.name',
    'section.text.data.name',
    'section.subsection.data.name',
    'section.subsection.data.name.name',
    'section.subsection.data.data.name',
    'section.subsection.data.data.contactName',
    'section.subsection.data.data.phone',
    'section.subsection.data.data.email',
    'section.subsection.data.text.data.name',
    'section.subsection.data.text.data.data.name',
    'section.subsection.data.data.data.name',
    'section.subsection.data.data.text.data.name',
    'section.subsection.data.data.text.data.data.name',
    'section.subsection.data.data.data.data.name',
    'section.subsection.data.data.data.text.data.name',
    'section.subsection.data.data.data.text.data.data.name',
    'section.subsection.data.data.data.data.data.name',
    'section.subsection.data.data.data.data.text.data.name',
    'section.subsection.data.data.data.data.text.data.data.name',
    'section.subsection.data.data.caption.data.name',
    'section.subsection.data.data.caption.data.data.name',
    'section.subsection.data.data.caption.data.data.data.name',
    'section.subsection.data.data.tabComponent.data.text.data.name',
    'section.subsection.data.data.title',
  ],
  includeScore: true,
  includeMatches: true,
  distance: 500,
  threshold: 0.01,
  ignoreLocation: true,
};

const sectionOptions: Fuse.IFuseOptions<JSONObject> = {
  keys: [
    'title.name',
    'subtitle.name',
    'text.data.name',
    'subsection.data.name',
    'subsection.data.name.name',
    'subsection.data.data.name',
    'subsection.data.data.contactName',
    'subsection.data.data.phone',
    'subsection.data.data.email',
    'subsection.data.text.data.name',
    'subsection.data.text.data.data.name',
    'subsection.data.data.data.name',
    'subsection.data.data.text.data.name',
    'subsection.data.data.text.data.data.name',
    'subsection.data.data.data.data.name',
    'subsection.data.data.data.text.data.name',
    'subsection.data.data.data.text.data.data.name',
    'subsection.data.data.data.data.data.name',
    'subsection.data.data.data.data.text.data.name',
    'subsection.data.data.data.data.text.data.data.name',
    'subsection.data.data.caption.data.name',
    'subsection.data.data.caption.data.data.name',
    'subsection.data.data.caption.data.data.data.name',
    'subsection.data.data.tabComponent.data.text.data.name',
    'subsection.data.data.title',
  ],
  includeScore: true,
  includeMatches: true,
  distance: 500,
  threshold: 0.01,
  ignoreLocation: true,
};

const subsectionOptions: Fuse.IFuseOptions<JSONObject> = {
  keys: [
    'data.name',
    'data.name.name',
    'data.data.name',
    'data.data.data.name',
    'data.data.contactName',
    'data.data.phone',
    'data.data.email',
    'data.data.title',
    'data.text.data.data.name',
    'data.text.data.name',
  ],
  includeScore: true,
  includeMatches: true,
  distance: 500,
  threshold: 0.01,
  ignoreLocation: true,
};

const fuseSearch = (
  dataToSearch: JSONObject[],
  searchOptions: Fuse.IFuseOptions<JSONObject> | undefined,
  termToSearch: string | Fuse.Expression
): Fuse.FuseResult<JSONObject>[] => {
  const fuse = new Fuse(dataToSearch, searchOptions);
  const results = fuse.search(termToSearch);
  return results;
};

const RESULTS_PER_PAGE = 4;
const BOUNDARY_RANGE = 8;
const SIBLING_RANGE = 1;

const initialPageState = {
  numResults: 0,
  resultsPerPage: RESULTS_PER_PAGE,
  currentPage: 1,
  resultsData: [],
  currentResults: [],
  boundaryRange: BOUNDARY_RANGE,
  siblingRange: SIBLING_RANGE,
};

type PageAction = {
  type: string;
  updatedState?: PageState;
};

type PageState = {
  numResults?: number;
  resultsPerPage?: number;
  currentPage?: number;
  resultsData?: SearchResultListProps[] | [];
  currentResults?: SearchResultListProps[] | [];
  boundaryRange?: number;
  siblingRange?: number;
};

const paginationReducer = (state: PageState, action: PageAction): PageState => {
  const totalPages =
    state.numResults && state.resultsPerPage
      ? Math.ceil(state.numResults / state.resultsPerPage)
      : 0;
  let updatedState = { ...action.updatedState };

  switch (action.type) {
    case 'UPDATE_RESULTS':
      updatedState = {
        ...state,
        numResults: updatedState && updatedState.resultsData ? updatedState.resultsData.length : 0,
        resultsPerPage: RESULTS_PER_PAGE,
        currentPage: 1,
        resultsData: updatedState.resultsData || [],
      };
      break;
    case 'NEXT':
      if (!state.currentPage || state.currentPage === totalPages) {
        updatedState = { ...state, currentPage: 1 };
      } else {
        updatedState = { ...state, currentPage: state.currentPage + 1 };
      }
      break;
    case 'PREV':
      if (!state.currentPage || state.currentPage === 1) {
        updatedState = { ...state, currentPage: totalPages };
      } else {
        updatedState = { ...state, currentPage: state.currentPage - 1 };
      }
      break;
    case 'SELECTED':
      if (action.updatedState) {
        updatedState = { ...state, currentPage: action.updatedState?.currentPage };
      }
      break;
    case 'SET':
      updatedState = action.updatedState ? { ...action.updatedState } : { ...state };
      break;
    case 'RESET':
      updatedState = { ...initialPageState };
      break;
    default:
      updatedState = { ...state };
  }

  const indexOfLastPost =
    (updatedState.currentPage || 1) * (updatedState.resultsPerPage || RESULTS_PER_PAGE);
  const indexOfFirstPost = indexOfLastPost - (updatedState.resultsPerPage || RESULTS_PER_PAGE);

  return {
    ...updatedState,
    currentResults: updatedState.resultsData
      ? updatedState.resultsData.slice(indexOfFirstPost, indexOfLastPost)
      : [],
  };
};

type SearchProps = {
  searchTerm: string;
};

const Search = ({ searchTerm }: SearchProps): JSX.Element => {
  const [searchData, setSearchData] = useState<JSONObject[] | []>([]);
  const [pagination, dispatchPagination] = useReducer(paginationReducer, initialPageState);

  const currentBreakpoint = useCurrentBreakpoint();
  const isMobile = currentBreakpoint === 'small';

  const { appDocument } = useContext(AppContext);

  useEffect(() => {
    if (appDocument && JSON.stringify(appDocument) !== '{}') {
      setSearchData(appDocument.data.map((page: JSONObject) => page));
    }
  }, [appDocument]);

  const formatResults = useCallback(
    (results: Fuse.FuseResult<JSONObject>[]): SearchResultListProps[] | [] => {
      const allResults: SearchResultListProps[] = [];
      let count = 0;
      results.forEach((item) => {
        item.matches?.forEach((result) => {
          let sectionTitle: string = '';
          let sectionRoute: string = '';
          if (result.value) {
            const sectionResults = fuseSearch(item.item.section, sectionOptions, result.value);
            // There will only ever be one result
            sectionTitle = sectionResults[0]?.item?.route?.name || '';

            sectionRoute = sectionResults[0]?.item?.route?.path || '';
            const sectionIndex: number = sectionResults[0].refIndex;

            if (sectionIndex) {
              const key: string = result.key || '';
              if (key.includes('subsection')) {
                const subsectionResults = fuseSearch(
                  item.item.section[sectionIndex].subsection,
                  subsectionOptions,
                  result.value
                );

                if (
                  subsectionResults[0] &&
                  subsectionResults[0].item.data[0].type === 'hash-route'
                ) {
                  sectionRoute = subsectionResults[0].item.data[0].path;
                  sectionTitle = subsectionResults[0].item.data[0].name;
                }
              }
            }
          }
          let content: string | undefined = '';

          if (isMobile) {
            content = result.value?.substring(0, 120);
          } else {
            content = result.value;
          }
          const div = document.createElement('div');
          div.innerHTML = content as string;
          const text = div.textContent || div.innerText || '';

          if (item.item?.route?.name !== 'Home') {
            const aResult = {
              id: count,
              page: item.item?.route?.name,
              result: text,
              title: sectionTitle,
              destination: sectionRoute,
            };
            allResults.push(aResult);
            count += 1;
          }
        });
      });
      return allResults;
    },
    [isMobile]
  );

  useEffect(() => {
    if (searchData && searchTerm) {
      dispatchPagination({
        type: 'UPDATE_RESULTS',
        updatedState: {
          resultsData: formatResults(fuseSearch(searchData, options, searchTerm)),
        },
      });
    }
  }, [searchData, searchTerm, formatResults]);

  const nextPage = (): void => dispatchPagination({ type: 'NEXT' });

  const prevPage = (): void => dispatchPagination({ type: 'PREV' });

  // on pagination index selected get the index from the elements data attribute
  // pass the index as the current page to the dispatcher
  const selectedPage = (event: React.MouseEvent<HTMLButtonElement>): void => {
    dispatchPagination({
      type: 'SELECTED',
      updatedState: {
        currentPage: parseInt((event.target as HTMLButtonElement).dataset?.pageIndex as string, 10),
      },
    });
  };

  return (
    <div className={styles.Search}>
      <div className={styles.Search_NumResults}>
        {pagination.numResults} {pagination.numResults === 1 ? 'result' : 'results'}
      </div>
      <SearchResultList data={pagination.currentResults || []} />
      <Pagination
        currentPage={pagination.currentPage || 1}
        numResults={pagination.numResults || 0}
        resultsPerPage={pagination.resultsPerPage || RESULTS_PER_PAGE}
        boundaryRange={BOUNDARY_RANGE}
        siblingRange={SIBLING_RANGE}
        nextPage={nextPage}
        prevPage={prevPage}
        selectedPage={selectedPage}
      />
    </div>
  );
};

export default Search;
