import React from 'react';
import PropTypes from 'prop-types';
import { CarouselBars as CarouselBars$1 } from '@vds-core/carousels';
import { ColorTokens } from '@vds-tokens/color';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark']),
  /**
   * The current selected slide/bar of the carousel.
   */
  selectedSlide: PropTypes.number
};
const defaultProps = {
  surface: 'light'
};
const CarouselBars = function (props) {
  function _calculateBarColor(selected, surface) {
    let barColor;
    let darkMode = surface === 'dark';
    if (selected && !darkMode) {
      barColor = ColorTokens.elements.primary.onlight.value;
    } else if (selected && darkMode) {
      barColor = ColorTokens.elements.primary.ondark.value;
    } else if (!selected && darkMode) {
      barColor = ColorTokens.elements.secondary.ondark.value;
    } else {
      barColor = ColorTokens.elements.secondary.onlight.value;
    }
    return barColor;
  }
  return React.createElement(CarouselBars$1, Object.assign({}, props, {
    calculateBarColor: _calculateBarColor,
    borderRadius: "2px"
  }));
};
CarouselBars.propTypes = propTypes;
CarouselBars.defaultProps = defaultProps;

export { CarouselBars as default };
