import { useReducer } from 'react';

interface Field {
  id: string;
}

const reducer = <S, A extends Field>(state: S, action: A): S => ({
  ...state,
  [action.id]: action,
});

const useFormReducer = <S>(initState: S): ReturnType<typeof useReducer> => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setField = <A extends Field>(action: A): void => dispatch(action);

  return [state, setField];
};

export default useFormReducer;
