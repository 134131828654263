const cognitoAuthFlowType = process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE as string;

const cognitoRegion = process.env.REACT_APP_COGNITO_REGION as string;

const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID as string;

const cognitoUserPoolClientId = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID as string;

const cognitoOAuthRedirectSignIn = process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN as string;

const cognitoOAuthRedirectSignOut = process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT as string;

const ssoVerizonHostUxUrlRedirect = process.env
  .REACT_APP_SSO_VERIZON_EMPLOYEES_HOST_UX_URL_REDIRECT as string;

const ssoDapHostUxUrlRedirect = process.env.REACT_APP_SSO_DAP_HOST_UX_URL_REDIRECT as string;

const cognitoOAuthScope = process.env.REACT_APP_COGNITO_OAUTH_SCOPE?.split(',') as string[];

const cognitoOAuthResponseType = process.env.REACT_APP_COGNITO_OAUTH_RESPONSE_TYPE as string;

const cognitoOAuthDomain = process.env.REACT_APP_COGNITO_OAUTH_DOMAIN as string;

const noAuthRequired = process.env.REACT_APP_NO_AUTH_REQUIRED as string;

const routingData = process.env.REACT_APP_ROUTING_DATA as string;

const header = process.env.REACT_APP_HEADER_SELECTION as string;

export {
  cognitoAuthFlowType,
  cognitoRegion,
  cognitoUserPoolId,
  cognitoUserPoolClientId,
  cognitoOAuthRedirectSignIn,
  cognitoOAuthRedirectSignOut,
  ssoVerizonHostUxUrlRedirect,
  ssoDapHostUxUrlRedirect,
  cognitoOAuthScope,
  cognitoOAuthDomain,
  cognitoOAuthResponseType,
  noAuthRequired,
  routingData,
  header,
};
