import { useLocation } from 'react-router-dom';

import styles from '../../containers/Container.module.scss';

import Footer from '../../components/Footer';
import SearchSection from './SearchSection';
import Breadcrumbs from '../../components/Breadcrumbs';
import Search from '../../components/Search';

const SearchResults = (): JSX.Element => {
  const { search } = useLocation();
  const searchValue = search.substring(3);

  return (
    <div className={styles.resultsContainer}>
      <Breadcrumbs />
      <SearchSection searchTerm={searchValue} hideAlert />
      <Search searchTerm={searchValue} />
      <Footer />
    </div>
  );
};

export default SearchResults;
