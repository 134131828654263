/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState, useEffect } from 'react';

import JSONObject from '../models/JSONObject';

import { AppContext } from '../containers/App';

const findObject = (
  obj: JSONObject,
  searchKey: string,
  searchValue: string,
  returnAll: boolean
): any | null => {
  const result = [] as JSONObject[];

  const search = (o: JSONObject, k: string, v: string): void => {
    if (!o || typeof o !== 'object') {
      return;
    }
    if (o[k as keyof JSONObject] === v) {
      result.push(o);
    }
    Object.keys(o).forEach((keyItem) => search(o[keyItem as keyof JSONObject], k, v));
  };

  search(obj, searchKey, searchValue);

  if (result.length === 0) {
    return null;
  }
  if (returnAll) {
    return result;
  }

  return result[0];
};

const useSearchObject = (
  searchKey: string,
  searchValue: string,
  returnAll: boolean
): any | null => {
  const { appDocument } = useContext(AppContext);

  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    if (appDocument && JSON.stringify(appDocument) !== '{}') {
      setData(findObject(appDocument.data, searchKey, searchValue, returnAll));
    }
  }, [searchKey, searchValue, returnAll, appDocument]);

  return data;
};

export default useSearchObject;
