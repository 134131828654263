/* eslint-disable react/no-danger */
import { useHistory } from 'react-router-dom';

import { Title } from '@vds/typography';
import styles from './AutocompleteResults.module.scss';

const formatResult = (result: string, searchTerm: string): JSX.Element => {
  const location: number = result.search(searchTerm);
  const beginningSpan = '<span style="color:black;">';
  const endSpan = '</span>';
  const { length } = searchTerm;
  const end = location + length;
  const output = [result.slice(0, end), endSpan, result.slice(end)].join('');
  const finalOutput = [output.slice(0, location), beginningSpan, output.slice(location)].join('');

  return <div className="Container" dangerouslySetInnerHTML={{ __html: finalOutput }} />;
};

type AutocompleteResultsProps = {
  results: string[];
  searchTerm: string;
};

const AutocompleteResults = ({ results, searchTerm }: AutocompleteResultsProps): JSX.Element => {
  const history = useHistory();

  return (
    <span className={styles.AutocompleteResults}>
      {searchTerm &&
        results.length >= 1 &&
        results.map((result) => (
          <button onClick={() => history.push(`/results/?s=${result}`)} type="button">
            <Title size="large" primitive="span">
              {formatResult(result, searchTerm)}
            </Title>
          </button>
        ))}
    </span>
  );
};

export default AutocompleteResults;
