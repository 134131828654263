const getTypographyType = (style: string | undefined): string => {
  let size = '';
  switch (style) {
    case 'small':
      size = 'small';
      break;
    case 'medium':
      size = 'medium';
      break;
    case 'large':
      size = 'large';
      break;
    case 'extralarge':
      size = 'large';
      break;
    case 'extraExtraLarge':
      size = 'large';
      break;
    default:
      size = 'medium';
  }
  return size;
};

export default getTypographyType;
