import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = (): JSX.Element => {
  return (
    <div>
      <svg
        className={styles.svg}
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.6 21.6"
      >
        <path d="M18.7,10.8h1.1a9,9,0,1,1-9-9V2.9a7.9,7.9,0,1,0,7.9,7.9Z" />
      </svg>
    </div>
  );
};

export default LoadingIndicator;
