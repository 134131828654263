/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

// Forked from
// https://github.com/rafgraph/react-router-hash-link
// Line 27: added timeout so scroll doesn't happen before layout;

import React, { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

let hashFragment = '';
let observer: MutationObserver | null = null;
let asyncTimerId: number | null = null;
let scrollFunction: Function | null = null;

const reset = (): void => {
  hashFragment = '';
  if (observer !== null) observer.disconnect();
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
};

const getElAndScroll = (): boolean => {
  const adjustedHashFragment = hashFragment.includes('/')
    ? hashFragment.substr(hashFragment.lastIndexOf('/') + 1)
    : hashFragment;
  const element = document.getElementById(adjustedHashFragment);
  if (element !== null) {
    // added timeout so scroll doesn't happen before layout;
    window.setTimeout(() => {
      if (scrollFunction) {
        scrollFunction(element);
        reset();
      }
    }, 500);
    return true;
  }
  return false;
};

const hashLinkScroll = (): void => {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }
      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      // if the element doesn't show up in 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, 10000);
    }
  }, 0);
};

const genericHashLink = (As: any): any => {
  return React.forwardRef((props, ref) => {
    const handleClick = (e: SyntheticEvent): void => {
      reset();
      if (props.onClick) props.onClick(e);
      hashFragment = props.to;
      if (hashFragment !== '') {
        scrollFunction =
          props.scroll ||
          ((el) =>
            props.smooth ? el.scrollIntoView({ behavior: 'smooth' }) : el.scrollIntoView());
        hashLinkScroll();
      }
    };
    const { scroll, smooth, ...filteredProps } = props;
    return (
      <As {...filteredProps} onClick={handleClick} ref={ref}>
        {props.children}
      </As>
    );
  });
};

export const HashLink = genericHashLink(Link);

export const NavHashLink = genericHashLink(NavLink);

const propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  scroll: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

HashLink.propTypes = propTypes;
NavHashLink.propTypes = propTypes;
