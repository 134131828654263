import React from 'react';
import PropTypes from 'prop-types';
import { CarouselScrubber } from '@vds-core/carousels';

const propTypes = {
  /**
   * Option to render on dark or light surface
   */
  surface: PropTypes.oneOf(['light', 'dark'])
};
const defaultProps = {
  surface: 'light'
};
const CarouselScrollbar = function (props) {
  return React.createElement(CarouselScrubber, Object.assign({}, props));
};
CarouselScrollbar.propTypes = propTypes;
CarouselScrollbar.defaultProps = defaultProps;

export { CarouselScrollbar as default };
