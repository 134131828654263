/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */

import {
  ComponentType,
  lazy,
  ReactElement,
  useRef,
  useEffect,
  useContext,
  useState,
  Suspense,
} from 'react';
import { Route, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { getCurrentAuthenticatedUser, getCurrentSession } from '../../lib/authenticationApi';
import { AppContext } from '../../containers/App';
import AppDocument from '../../models/AppDocument';
import getAppDocument from '../../lib/getAppDocument';

import PageSkeleton from '../PageSkeleton';
import LoadingIndicator from '../LoadingIndicator';
import styles from '../../containers/Container.module.scss';
import { noAuthRequired } from '../../lib/appGlobals';

type ProtectedRouteProps = {
  key?: string;
  path: string;
  exactPath: boolean;
  component?: string;
  index?: number;
  children?: ReactElement;
};

interface CustomRouteProps {
  rootKey: string;
}

const renderLazyComponent = (
  LazyComponent: ComponentType,
  props: RouteProps,
  customProps: CustomRouteProps
): JSX.Element => {
  const combinedProps = { ...props, ...customProps };
  return <LazyComponent {...combinedProps} />;
};

function ProtectedRoute({
  key,
  exactPath,
  path,
  component,
  index,
  children,
}: ProtectedRouteProps): JSX.Element {
  const history = useHistory();
  const currentUrl = useRef('');
  const [isUserSession, setIsUserSession] = useState<boolean>(false);
  const currentPath = history.location.pathname;
  if (currentUrl.current !== undefined) {
    currentUrl.current = currentPath as string;
  }
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<CognitoUserInterface | undefined>({} as CognitoUserInterface);
  const { handleSetIsAuth } = useContext(AppContext);
  const [appData, setAppData] = useState<AppDocument>({} as AppDocument);

  useEffect(() => {
    const sendUserInfoGetAppData = (): void => {
      if (Object.keys(appData).length > 0 && handleSetIsAuth) {
        if (user) {
          handleSetIsAuth(true, user, appData);
        }
      }
    };
    const getContentData = async (): Promise<void> => {
      const appDocument = await getAppDocument();
      setAppData(appDocument);
      sendUserInfoGetAppData();
      setIsLoading(false);
    };
    const getUser = async (): Promise<void> => {
      const userResult = await getCurrentAuthenticatedUser();
      if (userResult.hasError === true) {
        setIsUserSession(false);
        setIsLoading(false);
      } else if (userResult.hasError === false) {
        setUser(userResult.data as CognitoUserInterface);
        setIsUserSession(true);
        await getContentData();
      }
    };
    const getUserSession = async (): Promise<boolean> => {
      if (isUserSession === false) {
        const userSession = await getCurrentSession();
        if (userSession.hasError === true) {
          setIsUserSession(false);
          setIsLoading(false);
        } else if (userSession.hasError === false) {
          await getUser();
        }
      } else if (isUserSession === true) {
        await getUser();
      }
      return isUserSession;
    };
    if (noAuthRequired === 'true') {
      setIsUserSession(true);
      getContentData();
    } else {
      getUserSession();
    }
  }, [isLoading]);

  return (
    <Suspense
      fallback={
        <div className={styles.fetchingLoader}>
          {currentPath === '/home' ? <LoadingIndicator /> : <PageSkeleton />}
        </div>
      }
    >
      {!isLoading && (
        <>
          {isUserSession && (
            <>
              <Route
                exact={exactPath}
                key={key}
                path={currentUrl.current ? currentUrl.current : path}
                render={(props) =>
                  renderLazyComponent(
                    lazy(() => import(`../../containers/${component}`)),
                    props,
                    { rootKey: `root-page${index}-1` }
                  )
                }
              />
              {children}
            </>
          )}
          {!isUserSession && <Redirect to="/" />}
        </>
      )}
    </Suspense>
  );
}

export default ProtectedRoute;
