import { Link } from 'react-router-dom';
import SearchResultListProps from '../../models/SearchResultListProps';
import SearchResultCard from '../SearchResultCard';

import styles from './SearchResultList.module.scss';

import BodyText from '../BodyText';

type SearchResultsProps = {
  data: SearchResultListProps[];
};

const SearchResults = ({ data }: SearchResultsProps): JSX.Element => (
  <div className={styles.SearchResults}>
    {data.map((item) => (
      <SearchResultCard
        key={item.id}
        page={item.page}
        result={item.result}
        title={item.title}
        destination={item.destination}
      />
    ))}{' '}
    <div className={styles.SearchResults_Endcap}>
      <BodyText textSize="medium">
        <span>Can&rsquo;t find what you need?</span>
      </BodyText>
      <Link to={{ pathname: 'mailto:varun.sastry@verizon.com' }} target="_blank">
        Contact us
      </Link>{' '}
    </div>
  </div>
);

export default SearchResults;
