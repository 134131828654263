import { createContext, useState, useEffect, Suspense } from 'react';

import { CognitoUserInterface } from '@aws-amplify/ui-components';
import Routes from '../components/Routes';
import StoreProps from '../models/StoreProps';
import JSONObject from '../models/JSONObject';
import ComponentRouteProps from '../models/ComponentRouteProps';
import SubsectionParagraphProps from '../models/SubsectionParagraphProps';

import { routingData } from '../lib/appGlobals';

import getSearchObjectParent from '../lib/getSearchObjectParent';
import getSearchObject from '../lib/getSearchObject';
import getGuidelinesLinks from '../lib/getGuidelinesLinks';
import GuideLineListProps from '../models/GuideLineListProps';
import AppDocument from '../models/AppDocument';

export const AppContext = createContext<Partial<StoreProps>>({});

// Login page is page0. Set home page to page1. Other pages should be after these pages.
// Assumes first two pages are Login and then Home page.
const rootHomeKey = 'root-page1-1';

function App(): JSX.Element {
  const [appDocument, setAppDocument] = useState<AppDocument>({} as AppDocument);
  const [routeList, setRouteList] = useState<ComponentRouteProps[] | []>([]);
  // const [routeList] = useState<ComponentRouteProps[] | []>(routeListData);
  const [hashRouteList, setHashRouteList] = useState<SubsectionParagraphProps[] | []>([]);
  const [searchData, setSearchData] = useState<JSONObject[] | []>([]);
  const [guidelinesList, setGuideLinesList] = useState<GuideLineListProps[] | []>([]);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUserInterface>({} as CognitoUserInterface);
  const handleSetIsAuth = (
    authStatus: boolean,
    currentUser: CognitoUserInterface,
    appData: AppDocument
  ): void => {
    setIsAuth(authStatus);
    setUser(currentUser);
    setAppDocument(appData);
  };

  useEffect(() => {
    async function getRouteListData(): Promise<void> {
      // you have to put routingData into a temp variable for the import statement to work.
      const strRoutingData = routingData;
      setRouteList((await import(`../data/${strRoutingData}`)).default);
    }

    getRouteListData();
  }, []);

  useEffect(() => {
    if (appDocument && JSON.stringify(appDocument) !== '{}') {
      setHashRouteList(getSearchObjectParent(appDocument, 'type', 'hash-route', true));
      setSearchData(appDocument.data.map((page: JSONObject) => page));
      setGuideLinesList(
        getGuidelinesLinks(getSearchObject(appDocument, 'key', rootHomeKey, true)[0])
      );
    }
  }, [appDocument]);
  return (
    <Suspense fallback={<div />}>
      {routeList && (
        <AppContext.Provider
          value={
            {
              appDocument,
              routeList,
              hashRouteList,
              searchData,
              guidelinesList,
              isAuth,
              user,
              handleSetIsAuth,
            } as StoreProps
          }
        >
          <Routes />
        </AppContext.Provider>
      )}
    </Suspense>
  );
}

export default App;
