/* eslint-disable react/require-default-props */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Body } from '@vds/typography';
import styles from './BodyText.module.scss';
import getBodyTextType from '../../lib/getBodyTextType';

type BodyTextProps = {
  className?: string;
  textSize?: string;
  children: JSX.Element | JSX.Element[];
  color?: string;
};

const BodyText = ({
  className = '',
  textSize = 'large',
  children,
  color,
}: BodyTextProps): JSX.Element => {
  /*  const ref = React.useCallback((node) => {
    if (!node) return;
    console.log('LOOK: ', ref);
  }, []); */
  const classes = [className, styles.BodyText];
  switch (textSize) {
    case 'extraExtraLarge':
      classes.push(styles.textSizeExtraExtraLarge);
      break;
    case 'extraLarge':
      classes.push(styles.textSizeExtraLarge);
      break;
    case 'large':
      classes.push(styles.textSizeLarge);
      break;
    case 'medium':
      classes.push(styles.textSizeMedium);
      break;
    case 'small':
      classes.push(styles.textSizeSmall);
      break;

    default:
      break;
  }
  // console.log('look: ', children);
  return (
    <div className={classes.join(' ')}>
      <Body size={getBodyTextType(textSize)} color={color || '#000000'}>
        {children}
      </Body>
    </div>
  );
};

export default BodyText;
