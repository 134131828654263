/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { useLocation } from 'react-router-dom';

import { Title } from '@vds/typography';
import { HashLink } from '../../lib/HashLink';

import TextProps from '../../models/TextProps';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbProps = {
  title?: TextProps;
};

const Breadcrumbs = ({ title }: BreadcrumbProps): JSX.Element => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  return (
    <div className={path === 'results' ? styles.BreadcrumbsResults : styles.Breadcrumbs}>
      <HashLink to="/home">
        <Title primitive="p">Home</Title>
      </HashLink>
      <Title primitive="p">
        {title ? ' /' : ''} {title?.name}
      </Title>
    </div>
  );
};

export default Breadcrumbs;
