/* eslint-disable indent */
import styles from './Pagination.module.scss';

type PaginationProps = {
  currentPage: number;
  numResults: number;
  resultsPerPage: number;
  boundaryRange: number;
  siblingRange: number;
  prevPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  nextPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

// business logic
// 1. IS total greater than size
//   1a. NO, print pages 1 - (x < range)
//   1b. YES, continue
// 2. IS current < 4
//   2a. YES, single ellipsis the end
//   2b. NO, continue
// 3. IS current < total - 3
//   3a. YES, double ellipsis
//   3b. NO, single ellipsis the start

// conditionals
// is index and current page between the start of the pagination '1' and '+[num]'
const isBetweenStartRange = (
  index: number,
  currentPage: number,
  boundaryRange: number,
  siblingTotal: number
): boolean => {
  return (
    index >= 1 &&
    index < boundaryRange - siblingTotal &&
    currentPage >= 1 &&
    currentPage < boundaryRange - siblingTotal - 1
  );
};

// is index and current page between the end of the pagination 'totalPage' and '-[num]'
const isBetweenEndRange = (
  index: number,
  currentPage: number,
  totalPages: number,
  siblingTotal: number
): boolean => {
  return (
    index <= totalPages &&
    index >= totalPages - siblingTotal - 2 &&
    currentPage <= totalPages &&
    currentPage >= totalPages - siblingTotal - 1
  );
};

// is index between current page -+ siblingRange to show previous and next pages
const isBetweenCurrentPageRange = (
  index: number,
  currentPage: number,
  siblingRange: number
): boolean => {
  return index >= currentPage - siblingRange && index <= currentPage + siblingRange;
};

// show ellipsis
const showEllipsis = (
  index: number,
  currentPage: number,
  totalPages: number,
  boundaryRange: number,
  siblingTotal: number
): boolean => {
  return (
    (currentPage >= boundaryRange - siblingTotal - 1 && index === 2) ||
    (currentPage <= totalPages - siblingTotal - 2 && index === totalPages)
  );
};

const Pagination = ({
  currentPage,
  numResults,
  resultsPerPage,
  boundaryRange,
  siblingRange,
  prevPage,
  nextPage,
  selectedPage,
}: PaginationProps): JSX.Element => {
  // ui/ux reference: https://react.semantic-ui.com/maximize/pagination-example-options/
  const siblingTotal: number = siblingRange * 2; // accounts for both sides of current selected page
  const totalPages = Math.ceil(numResults / resultsPerPage);
  const pages: number[] = Array.from({ length: totalPages }, (_, i) => i + 1);

  const BuildPagination = (): JSX.Element => {
    return (
      <>
        {pages.map((x: number) => (
          <span key={`b-${x}`}>
            {totalPages > boundaryRange &&
              showEllipsis(x, currentPage, totalPages, boundaryRange, siblingTotal) && (
                <span key={`e${x}`} className="ellipsis">
                  ...
                </span>
              )}
            {(totalPages <= boundaryRange ||
              (totalPages > boundaryRange &&
                (isBetweenStartRange(x, currentPage, boundaryRange, siblingTotal) ||
                  isBetweenEndRange(x, currentPage, totalPages, siblingTotal) ||
                  isBetweenCurrentPageRange(x, currentPage, siblingTotal) ||
                  x === 1 ||
                  x === totalPages))) && (
              <button
                key={`p${x}`}
                type="button"
                onClick={selectedPage}
                data-page-index={x}
                className={currentPage === x ? styles.Active : ''}
              >
                {x}
              </button>
            )}
          </span>
        ))}
      </>
    );
  };

  return totalPages > 1 ? (
    <div className={styles.Pagination}>
      {numResults > resultsPerPage ? (
        <button aria-label="button" type="button" className={styles.Next} onClick={prevPage}>
          <svg
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.50928 11.0161L1.50192 6.00876L6.50928 1.00141"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </button>
      ) : (
        ''
      )}
      <BuildPagination />
      {numResults > resultsPerPage ? (
        <button aria-label="button" type="button" className={styles.Prev} onClick={nextPage}>
          <svg
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.50244 1.00146L6.50979 6.00882L1.50244 11.0162"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </button>
      ) : (
        ''
      )}
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
