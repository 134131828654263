/* eslint-disable @typescript-eslint/no-explicit-any */
import JSONObject from '../models/JSONObject';

import AppDocument from '../models/AppDocument';

const findObject = (
  obj: JSONObject,
  searchKey: string,
  searchValue: string,
  returnAll: boolean
): any | null => {
  const result = [] as JSONObject[];

  const search = (o: JSONObject, k: string, v: string): void => {
    if (!o || typeof o !== 'object') {
      return;
    }
    if (o[k as keyof JSONObject] === v) {
      result.push(o);
    }
    Object.keys(o).forEach((keyItem) => search(o[keyItem as keyof JSONObject], k, v));
  };

  search(obj, searchKey, searchValue);

  if (result.length === 0) {
    return null;
  }
  if (returnAll) {
    return result;
  }

  return result[0];
};

const getSearchObject = (
  appDocument: AppDocument,
  searchKey: string,
  searchValue: string,
  returnAll: boolean
): any | null => findObject(appDocument.data, searchKey, searchValue, returnAll);

export default getSearchObject;
