/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
// import { useHistory } from 'react-router-dom';

import styles from './SearchResultCard.module.scss';

import { HashLink } from '../../lib/HashLink';

import dangerouslySetInnerHTMLProps from '../../models/dangerouslySetInnerHTMLProps';

import createMarkup from '../../lib/createMarkup';

const formatResult = (result: string): dangerouslySetInnerHTMLProps => {
  let temp = result;
  if (result[result.length - 1] === ' ') {
    temp = result.slice(0, -1);
  }
  const markup = createMarkup('html', temp);
  return markup;
};

const renderEllipses = (result: string): JSX.Element => {
  if (result[result.length - 1] === '.' || result.substring(result.length - 10) === '.</strong>') {
    return <></>;
  }
  return <span className={styles.Ellipses}>...</span>;
};

type SearchResultCardProps = {
  page?: string | undefined;
  result?: string;
  title: string;
  destination: string;
};

const SearchResultCard = ({
  page,
  result,
  title,
  destination,
}: SearchResultCardProps): JSX.Element => {
  return (
    <HashLink to={`../${destination}`} className={styles.SearchResultCard}>
      <div className={styles.SearchResultCard_Subtitle}>{page}</div>
      <div className={styles.SearchResultCard_Title}>{title}</div>
      {result && (
        <>
          <div
            className={styles.SearchResultCard_Body}
            dangerouslySetInnerHTML={formatResult(result)}
          />
        </>
      )}
      {renderEllipses(result!)}
    </HashLink>
  );
};

export default SearchResultCard;
