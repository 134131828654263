/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import { useContext, Suspense, ComponentType, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, RouteProps } from 'react-router-dom';

import SearchResults from '../../layout/home/SearchResults';

import { AppContext } from '../../containers/App';
import PageSkeleton from '../PageSkeleton';
import ProtectedRoute from '../ProtectedRoute';
import FallbackRoute from '../FallbackRoute';
import styles from '../../containers/Container.module.scss';

interface CustomRouteProps {
  rootKey: string;
}

const renderLazyComponent = (
  LazyComponent: ComponentType,
  props: RouteProps,
  customProps: CustomRouteProps
): JSX.Element => {
  const combinedProps = { ...props, ...customProps };
  return <LazyComponent {...combinedProps} />;
};

const Routes = (): JSX.Element => {
  const { routeList } = useContext(AppContext);
  return (
    <div>
      <Router>
        <Switch>
          {routeList &&
            routeList.length !== 0 &&
            routeList.map(({ exactPath, key, path, component }, i) => {
              if (path === '/' && exactPath) {
                return (
                  <Route
                    exact={exactPath}
                    key={key}
                    path={path}
                    render={(props) =>
                      renderLazyComponent(
                        lazy(() => import(`../../containers/${component}`)),
                        props,
                        { rootKey: `root-page${i}-1` }
                      )
                    }
                  />
                );
              }
              return (
                <ProtectedRoute
                  exactPath={exactPath}
                  key={key}
                  path={path}
                  component={component}
                  index={i}
                />
              );
            })}
          <Route exact path="/results">
            <Suspense
              fallback={
                <div className={styles.fetchingLoader}>
                  <PageSkeleton />
                </div>
              }
            >
              <SearchResults />
            </Suspense>
          </Route>
          <Route path="*">
            <Suspense
              fallback={
                <div className={styles.fetchingLoader}>
                  <PageSkeleton />
                </div>
              }
            >
              <FallbackRoute />
            </Suspense>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
