import { useEffect, useState, useCallback, useRef } from 'react';

const BREAKPOINTS = {
  small: '567px',
  smallEnd: '767px',
  medium: '768px',
  mediumEnd: '959px',
  large: '960px',
  largeEnd: '1439px',
  xlarge: '1440px',
};

// based on https://usehooks.com/useMedia/
const useMedia = <T>(queries: string[], values: T[], defaultValue: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutRef = useRef<any>(null);
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  }, [defaultValue, mediaQueryLists, values]);

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = (): void => {
        clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          setValue(getValue);
        }, 0);
      };
      // Set a listener for each media query with above handler as callback.
      // mediaQueryLists.forEach((mql) => mql.addEventListener('change', handler));
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach((mql) => mql.removeEventListener('change', handler));
    },
    // eslint-disable-next-line
    [] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};

const useCurrentBreakpoint = (): string => {
  const value = useMedia(
    [
      `(min-width: ${BREAKPOINTS.xlarge})`,
      `(min-width: ${BREAKPOINTS.large})`,
      `(min-width: ${BREAKPOINTS.medium})`,
    ],
    ['xlarge', 'large', 'medium'],
    'small'
  );

  return value;
};

export { useCurrentBreakpoint, BREAKPOINTS };
