/* eslint-disable no-console */
import axios from 'axios';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { getCurrentSession } from './authenticationApi';
import api from './api';
import { noAuthRequired } from './appGlobals';

import AppDocument from '../models/AppDocument';

const contentfulUrl = '/api/content';

const getAppDocument = async (): Promise<AppDocument> => {
  let token = '';

  if (noAuthRequired === 'true') {
    token = sessionStorage.getItem('token') || '';
  } else {
    token = ((await getCurrentSession()).data as CognitoUserSession).getIdToken().getJwtToken();
  }
  const source = axios.CancelToken.source();

  try {
    const response = await api.get({
      url: token ? `${contentfulUrl}?token=${token}` : contentfulUrl,
      cancelToken: source.token,
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled');
    } else {
      // handle the error.
      console.log(error);
    }
    return {} as AppDocument;
  }
};

export default getAppDocument;
