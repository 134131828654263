/* eslint-disable @typescript-eslint/no-explicit-any */
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import {
  cognitoRegion,
  cognitoUserPoolId,
  cognitoUserPoolClientId,
  cognitoAuthFlowType,
  cognitoOAuthDomain,
  cognitoOAuthScope,
  cognitoOAuthRedirectSignIn,
  cognitoOAuthRedirectSignOut,
  cognitoOAuthResponseType,
} from './appGlobals';
import CognitoError from '../models/CognitoError';
import CognitoSignIn from '../models/CognitoSignIn';
// import CognitoForgotPassword from '../models/CognitoForgotPassword';
import CognitoResult from '../models/CognitoResult';
import CognitoSsoSignIn from '../models/CognitoSsoSignIn';
import CognitoFlow from '../models/CognitoFlow';
import CognitoForgotPasswordSubmit from '../models/CognitoForgotPasswordSubmit';
import CognitoChangePassword from '../models/CognitoChangePassword';

// Put amplify configure stuff in here and then put in the functions, and export all of them

Amplify.configure({
  Auth: {
    region: cognitoRegion,
    userPoolId: cognitoUserPoolId,
    userPoolWebClientId: cognitoUserPoolClientId,
    authenticationFlowType: cognitoAuthFlowType,
  },
  oauth: {
    domain: cognitoOAuthDomain,
    scope: cognitoOAuthScope,
    redirectSignIn: cognitoOAuthRedirectSignIn,
    redirectSignOut: cognitoOAuthRedirectSignOut,
    responseType: cognitoOAuthResponseType,
  },
});

async function getCurrentSession(): Promise<CognitoResult> {
  try {
    const response = await Auth.currentSession();
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function getCurrentUserPoolUser(): Promise<CognitoResult> {
  try {
    const response = await Auth.currentUserPoolUser();
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function getCurrentAuthenticatedUser(): Promise<CognitoResult> {
  try {
    const response = await Auth.currentAuthenticatedUser();
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function signIn(data: CognitoSignIn): Promise<CognitoResult> {
  const { email, password } = data;
  try {
    const response = await Auth.signIn(email, password);
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function signOut(): Promise<CognitoResult> {
  try {
    const response = await Auth.signOut();
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function ssoSignInInitialRedirect(): Promise<CognitoResult> {
  try {
    const response = await Auth.federatedSignIn();
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function ssoSignIn(data: CognitoSsoSignIn): Promise<CognitoResult> {
  const { url } = data;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const response = await Auth._oAuthHandler.handleAuthResponse(url);
    const { accessToken, idToken, refreshToken } = response;
    const newAccessToken = new CognitoAccessToken({ AccessToken: accessToken });
    const newIdToken = new CognitoIdToken({ IdToken: idToken });
    const newRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    const session = new CognitoUserSession({
      AccessToken: newAccessToken,
      IdToken: newIdToken,
      RefreshToken: newRefreshToken,
    });
    const userPool = new CognitoUserPool({
      UserPoolId: cognitoUserPoolId,
      ClientId: cognitoUserPoolClientId,
    });
    const cognitoUser = new CognitoUser({
      Username: newAccessToken.payload.username,
      Pool: userPool,
    });
    cognitoUser.setSignInUserSession(session);
    const currentUser = await Auth.currentAuthenticatedUser();
    return { hasError: false, data: currentUser } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function forgotPassword(data: string): Promise<CognitoResult> {
  const email = data;
  try {
    const response = await Auth.forgotPassword(email);
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

export async function forgotPasswordSubmit(
  data: CognitoForgotPasswordSubmit
): Promise<CognitoResult> {
  const { email, authCode, newPassword } = data;
  try {
    const response = await Auth.forgotPasswordSubmit(email, authCode, newPassword);
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

async function handleSetNewPassword(
  user: CognitoUserInterface,
  password: string
): Promise<CognitoFlow> {
  let authState = '';
  let result;
  if (user.challengeParam.requiredAttributes !== undefined) {
    const newPwResult = await Auth.completeNewPassword(user, password);
    result = newPwResult;
    if (newPwResult.hasError) {
      const { code } = newPwResult.data as CognitoError;
      switch (code) {
        case 'PasswordResetRequiredException':
          authState = 'resetPassword';
          break;
        default:
          authState = 'error';
          break;
      }
    } else {
      const currentSessionResult = await getCurrentSession();
      result = currentSessionResult;
      if (currentSessionResult.hasError) {
        authState = 'error';
      } else {
        authState = 'authenticated';
      }
    }
  } else {
    authState = 'error';
  }
  return { authState, result };
}

async function handleSignInFlow(email: string, password: string): Promise<CognitoFlow> {
  let authState = '';
  let result;
  const signInResult = await signIn({ email, password });
  result = signInResult;
  if (signInResult.hasError) {
    const { code } = signInResult.data as CognitoError;
    switch (code) {
      case 'PasswordResetRequiredException':
        authState = 'resetPassword';
        break;
      default:
        authState = 'error';
        break;
    }
  } else {
    const user = signInResult.data as CognitoUserInterface;
    if (user.challengeName) {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        authState = 'resetPassword';
      } else {
        authState = 'error';
      }
    } else {
      const currentSessionResult = await getCurrentSession();
      result = currentSessionResult;
      if (currentSessionResult.hasError) {
        authState = 'error';
      } else {
        authState = 'authenticated';
      }
    }
  }
  return { authState, result };
}

// eslint-disable-next-line import/export
async function handleChangePassword(data: CognitoChangePassword): Promise<CognitoResult> {
  const { oldPassword, newPassword } = data;
  const user = await Auth.currentAuthenticatedUser();
  try {
    const response = await Auth.changePassword(user, oldPassword, newPassword);
    return { hasError: false, data: response } as CognitoResult;
  } catch (error: any) {
    return {
      hasError: true,
      data: {
        code: error.code,
        message: error.toString(),
      } as CognitoError,
    };
  }
}

export {
  forgotPassword,
  ssoSignIn,
  ssoSignInInitialRedirect,
  signIn,
  getCurrentSession,
  getCurrentAuthenticatedUser,
  signOut,
  handleSignInFlow,
  handleChangePassword,
  getCurrentUserPoolUser,
  handleSetNewPassword,
};
