/* eslint-disable react/require-default-props */
import styles from './AlertBanner.module.scss';
import { HashLink } from '../../lib/HashLink';

type AlertBannerProps = {
  name: string;
  href: string;
  bannerColor?: string;
  bgColor?: string;
  textColor?: string;
  bannerTextColor?: string;
};

const Caret = (): JSX.Element => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 15 15"
    className={styles.Caret}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.247.947l6.047 6.136-6.087 6.179.931.947L8.16 7.083 1.18 0z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

const AlertBanner = ({
  name,
  href,
  bannerColor,
  bgColor,
  textColor,
  bannerTextColor,
}: AlertBannerProps): JSX.Element => {
  const hasBorder = (): boolean => {
    return (
      bgColor === '#FFF' ||
      bgColor === '#FFFFFF' ||
      bgColor === '#fff' ||
      bgColor === '#ffffff' ||
      bgColor === 'white'
    );
  };
  const border = hasBorder();

  return (
    <div
      className={`${styles.AlertBanner} ${border ? styles.border : ''}`}
      style={bgColor ? { backgroundColor: bgColor } : { backgroundColor: '#F3EDE0' }}
    >
      <div
        className={styles.guidanceWrapper}
        style={bannerColor ? { backgroundColor: bannerColor } : { backgroundColor: '#F5FF1E' }}
      >
        <p
          className={styles.guidance}
          style={bannerTextColor ? { color: bannerTextColor } : { color: 'white' }}
        >
          New retail brand guidance
        </p>
      </div>
      <div className={styles.messageWrapper}>
        <h3
          className={styles.message}
          style={textColor ? { color: textColor } : { color: '#F50A23' }}
        >
          {name}
        </h3>
        <HashLink to={href} className={styles.link}>
          <div className={styles.UpdateContainer}>
            <p className={styles.update}>See update</p>
            <Caret />
          </div>
        </HashLink>
      </div>
    </div>
  );
};

export default AlertBanner;
