import { useState, useEffect, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import Bounds from '../models/Bounds';

const useMeasure = (ref: React.MutableRefObject<HTMLDivElement | null>): Bounds => {
  const [bounds, setBounds] = useState(() => {
    return { left: 0, top: 0, width: 0, height: 0 };
  });

  useLayoutEffect(() => {
    if (ref) {
      setBounds({
        left: 0,
        top: 0,
        width: ref.current?.clientWidth ?? 0,
        height: ref.current?.clientHeight ?? 0,
      });
    }
  }, [ref]);

  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        setBounds(entry.contentRect);
      })
  );

  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, [ref, ro]);
  return bounds;
};

export default useMeasure;
