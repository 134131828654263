import styles from './PageSkeleton.module.scss';

const PageSkeleton = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.dot} />
      </div>
      <div className={styles.main}>
        <div className={styles.leftNav}>
          {Array.from({ length: 10 }, (_item, index) => (
            <div className={styles.navItem} key={index} />
          ))}
        </div>
        <div className={styles.container}>
          {Array.from({ length: 2 }, (_item, index) => (
            <div className={styles.content} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageSkeleton;
